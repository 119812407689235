import React, {useState, useEffect} from 'react';
import ProfileUserModal from '../components/modal/profile-user'
import ProfileHomeModal from '../components/modal/profile-home'
import ProfileRoomServicerModal from '../components/modal/profile-room-servicer'
import ProfileServicerModal from '../components/modal/profile-servicer'
import ServicerAside from '../components/aside/servicer';
import RoomAside from '../components/aside/room';
import SettingAside from '../components/aside/setting';
import Navigation from '../components/navigation';
import ChatMain from '../components/main/chat';
import {UserHome, Room, Message, Customer, Servicer, Event, Text} from '../models/types';
import UserHomeService from '../services/user_home';
import ServicerService from '../services/servicer';
import MessageService from '../services/message';
import MediaService from '../services/media';
import DataOffCanvas from '../components/offcanvas/data';
import messageMp3 from '../assets/mp3/message.mp3';
// import FilesOffCanvas from "../components/offcanvas/files";
// import '../assets/js/template/toggle-offcanvas';
import { Tooltip } from 'bootstrap';
import autosize from 'autosize';

const HomePage = () => {

  const defaultCustomer: Customer = {
    id: 0,
    code: '',
    name: '',
    avatar: '',
    mobile: '',
    email: '',
    address: '',
    real_name: '',
    note: '',
  };

  const defaultUserHome: UserHome = {
    id: 0,
    code: '',
    name: '',
    avatar: '',
    mobile: '',
    email: '',
    address: '',
    customers: [],
    servicers: [],
    departments: [],
  };

  // const defaultServicer: Servicer = {
  //   id: 0,
  //   code: '',
  //   name: '',
  //   avatar: '',
  // };

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [userHome, setUserHome] = useState<UserHome>(defaultUserHome);
  const [servicers, setServicers] = useState<Servicer[]>([]);
  const [servicer, setServicer] = useState<Servicer>();
  const [checkedServicers, setCheckedServicers] = useState<Servicer[]>([]);
  const [room, setRoom] = useState<Room>();
  const [keyword, setKeyword] = useState<string>('');
  const [msgID, setMsgID] = useState<string>('');
  const [msgIndex, setMsgIndex] = useState<number>(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [reply, setReply] = useState<string>('');
  const [socket, setSocket] = useState<WebSocket>();

  const token = localStorage.getItem('token') || ''
  const host = process.env.NODE_ENV === 'development' ? 'localhost:8088' : window.location.host
  const chat_url = window.location.protocol.replace('http', 'ws') + '//' + host + '/chat?token=' + token;
  const audio = new Audio(messageMp3);

  const setMessageDisplay = (messageList: Message[]) => {
    let messagesWithDisplay: Message[] = [];
    if (messageList.length === 0) {
      return messagesWithDisplay;
    }
    if (messageList.length === 1) {
      messagesWithDisplay = messageList;
      messagesWithDisplay[0].display_time = messagesWithDisplay[0].send_time;
      return messagesWithDisplay;
    }
    for (let i = 0; i < messageList.length; i++) {
      let item: Message = messageList[i];
      if (i === 0) {
        item.display_time = messageList[i].send_time;
        messagesWithDisplay.push(item);
        continue
      }

      // 获取前一个和当前的时间
      const prevTime = new Date(messageList[i - 1].send_time * 1000);
      const currTime = new Date(messageList[i].send_time * 1000);

      // 获取日期部分
      const prevDate = prevTime.toLocaleString().split(' ')[0];
      const currDate = currTime.toLocaleString().split(' ')[0];

      // 跨天显示日期
      if (prevDate !== currDate) {
        item.display_time = messageList[i].send_time;
      }

      // 文件类型
      if (item.msgtype === "file") {
        MediaService.get_media_info(item.file!.media_id).then((e) => {
          item.file!.file_name = e.data.file_name
          item.file!.file_size = e.data.file_size
        }).catch((error) => {
          console.error('Error:', error);
        });
      }

      messagesWithDisplay.push(item);
    }

    return messagesWithDisplay;
  }

  useEffect(() => {
    const ws = new WebSocket(chat_url);
    setSocket(ws);
    return () => {
      ws.close();
    };
  }, [chat_url]);

  useEffect(() => {
    if (socket === undefined) {
      return
    }
    socket.onopen = (event) => {
      console.log("onopen:", event);
    }
    socket.onclose = (event) => {
      console.log("onclose:", event);
      // 断线重连
      setTimeout(() => {
        const ws = new WebSocket(chat_url);
        setSocket(ws);
      }, 3000);
    }
    socket.onerror = (event) => {
      console.log("onerror:", event);
    }
    socket.onmessage = (event) => {
      console.log("onmessage:", event);
      const message = JSON.parse(event.data as string) as Message;
      console.log('message:', message);
      console.log('room:', room);

      // 人工服务紧急提示
      let played = false;
      const personServiceKeywords = [
        "人工",
        "转人工",
        "转接人工",
        "人工服务",
        "人工接待",
        "人工咨询",
        "人工指导",
        "人工客服",
        "客服介入",
        "转接专员",
        "转接客服",
        "专属客服",
        "需要客服",
      ];
      if (personServiceKeywords.filter((item) => item === message.text?.content).length > 0) {
        audio.play();
        played = true;
      }

      if (room !== undefined && message.external_userid === room.customer.code && message.open_kfid === room.servicer.code) {
        if (messages.filter(item => item.msgid === message.msgid).length === 0) {
          setMessages([...messages, message]);

          // 语音提醒
          const switch_audio = localStorage.getItem("switch_audio") || "off";
          if (switch_audio === "on" && message.origin > 0 && !played) { // TODO 页面点击之后才能播放
            // if (switch_audio === "on" && message.origin > 0) {
            audio.play();
          }
        }
      }
      // 用户信息
      UserHomeService.get_user_home().then((e) => {
        console.log('Success:', e.data);
        setUserHome(e.data);
        // if (e.data.servicers.length > 0) {
        //   setServicers(e.data.servicers);
        // }
      }).catch((error) => {
        console.error('Error:', error);
      });

      // 客服列表
      const reqServicerList = {
        offset: 0,
        limit: 100,
      };
      ServicerService.get_list(reqServicerList).then((e) => {
        console.log('Success:', e.data);
        setServicers(e.data.data);
        setCheckedServicers(e.data.data.filter(obj => !checkedServicers.every(item => item.code !== obj.code)));
      }).catch((error) => {
        console.error('Error:', error);
      });
    }
  }, [socket, chat_url, messages, checkedServicers, servicers, room]);

  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    if (token !== "") {
      // 用户信息
      UserHomeService.get_user_home().then((e) => {
        console.log('Success:', e.data);
        setUserHome(e.data);
        // if (e.data.servicers.length > 0) {
        //   setServicers(e.data.servicers);
        // }
      }).catch((error) => {
        console.error('Error:', error);
      });
      // 客服列表
      const reqServicerList = {
        offset: 0,
        limit: 100,
      };
      ServicerService.get_list(reqServicerList).then((e) => {
        console.log('Success:', e.data);
        setServicers(e.data.data);
        setCheckedServicers(e.data.data.filter(obj => !checkedServicers.every(item => item.code !== obj.code)));
      }).catch((error) => {
        console.error('Error:', error);
      });
      return
    }
    window.location.href = '/login';
  }, []);

  useEffect(() => {
    if (room === undefined) {
      return
    }
    const reqGetMessage = {
      customer_code: room.customer.code,
      servicer_code: room.servicer.code,
      offset: offset,
      limit: limit,
    };

    MessageService.get_msg_list(reqGetMessage).then((e) => {
      console.log('Success:', e.data);
      const messageList = e.data.data.reverse()
      if ((messageList).length < limit) {
        setHasMore(false);
      }
      const roomMessages = messageList.filter((item) => item.open_kfid === room.servicer.code && item.external_userid === room.customer.code)
      const roomMessagesDisplay = setMessageDisplay(roomMessages)
      setMessages([...roomMessagesDisplay, ...messages]);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }, [room, offset]);

  // 自动缩放 (文本域输入框行数自动缩放)
  autosize(document.querySelectorAll('[data-autosize="true"]'));

  // 初始化 tooltip
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

  tooltipTriggerList.forEach(tooltip => {
    new Tooltip(tooltip, {
      html: true
    });
  });

  useEffect(() => {
    if (room === undefined) {
      return;
    }
    // 清空定位
    if (keyword === "") {
      setMsgIndex(0);
      setMsgID('');
      return;
    }
    const regex = new RegExp(keyword, 'g');
    const filterMessages = messages.filter((item) => {
      const matches = item.text?.content.match(regex);
      return (matches ? matches.length : 0) > 0;
    });
    console.log('filterMessages', filterMessages);
    console.log('msgIndex', msgIndex);
    if (filterMessages.length > 0) {
      // 循环定位
      let index = 0;
      if (msgIndex < filterMessages.length) {
        index = msgIndex;
      } else {
        index = msgIndex % filterMessages.length;
      }
      setMsgID(filterMessages[index].msgid.toString());
    }
    // if (msgIndex < filterMessages.length) {
    //   setMsgIndex(msgIndex + 1);
    // }
    // if (msgIndex === filterMessages.length) {
    //   setMsgIndex(0);
    // }
    // if (msgIndex > filterMessages.length) {
    //   setMsgIndex(msgIndex + 1);
    // }
  }, [room, messages, keyword, msgID, msgIndex]);

  // 侧边抽屉切换
  // const toggleChat = () => {
  //   const main = document.querySelector('.main');
  //   if (main === null) {
  //     return;
  //   }
  //   const elements = document.querySelectorAll('[data-toggle-chat]');
  //
  //   Array.prototype.forEach.call(elements, (element) => {
  //     element.addEventListener('click', () => {
  //       main.classList.toggle('is-visible');
  //     }, false);
  //   });
  // }
  // toggleChat();

  const nextPage = () => {
    if (!hasMore) {
      return
    }
    setOffset(offset + limit);
  }

  return (
    <>
      {/* 分类导航 */}
      <Navigation userHome={userHome}/>

      {/* 业务模块 */}
      <aside className="sidebar bg-light">
        <div className="tab-content h-100" role="tablist">

          {/* 客服列表 */}
          <ServicerAside
            servicers={servicers}
            setCheckedServicers={(servicers: Servicer[]) => {setCheckedServicers(servicers);}}
            setServicer={(servicer: Servicer) => {setServicer(servicer);}}
          />

          {/* 对话记录 */}
          <RoomAside
            servicers={checkedServicers}
            setRoom={
              (r: Room) => {
                if (r === room) {
                  return;
                }
                setKeyword('');
                setMsgIndex(0);
                setMsgID('');
                setOffset(0);
                setLimit(20);
                setHasMore(true);
                setMessages([]);
                setRoom(r);
                setCustomer(r.customer);
              }
            }
            setKeyword={
              (k: string) => {
                if (k === '' || k !== keyword) {
                  setMsgIndex(0);
                } else {
                  setMsgIndex(msgIndex + 1);
                }
                if (k !== '') {
                  setLimit(1000);
                  setHasMore(false);
                }
                setKeyword(k);
              }
            }
          />

          {/* 系统设置 */}
          <SettingAside userHome={userHome}/>

        </div>
      </aside>

      {/* 消息主体 */}
      <ChatMain
        room={room}
        messages={messages}
        reply={reply}
        keyword={keyword}
        msgID={msgID}
        nextPage={nextPage}
        offset={offset}
        hasMore={hasMore}
      />

      {/* 侧边抽屉 */}
      {/*<FilesOffCanvas/>*/}
      <DataOffCanvas setContent={(content: string) => setReply(content)}/>

      {/* 模态 */}
      <ProfileUserModal userInfo={customer}/>
      <ProfileHomeModal userHome={userHome}/>
      <ProfileRoomServicerModal servicer={room?.servicer}/>
      <ProfileServicerModal servicer={servicer}/>
    </>
  );
}

export default HomePage;
